import { api, homePageController } from '~/apis/back/services'
import type { IntelligentRecommendResponseDTO, RecommendDTO } from '~/apis/back/types'
import { E_RECOMMEND_CATEGORY } from '~/enums'

export function useIntelRecommand() {
  const intelRecommandState = useState('intelRecommandState', () => ({
    result: [] as IntelligentRecommendResponseDTO[],
    pageNum: 1,
    active: E_RECOMMEND_CATEGORY.ALL,
    loading: false,
  }))

  const {
    result,
    pageNum,
    active,
    loading,
  } = toRefs(intelRecommandState.value)

  async function fetchIntelRecommand() {
    const headers = useRequestHeaders(['x-forwarded-for', 'x-real-ip'])
    // 获取ip, 默认新加坡(454)
    const userIp = headers['x-forwarded-for'] || headers['x-real-ip'] || '454'

    loading.value = true

    const { data: cityData } = await api.regionRegionCurrentCityPost({ userIp })

    const { data } = await homePageController.homePageIntelligentRecommendPost({
      cityId: cityData?.value?.data?.id || 454,
    })

    loading.value = false

    result.value = data.value?.data || []
  }

  const recommendList = computed(() => {
    const resultNotEmpty = result.value.filter(item => (item.recommendList?.length ?? 0) > 0)
    return [
      {
        category: E_RECOMMEND_CATEGORY.ALL,
        recommendList: resultNotEmpty.map(item => item.recommendList).flat() as RecommendDTO[],
      },
      ...resultNotEmpty,
    ]
  })

  async function loadMore() {
    pageNum.value++
  }

  return {
    result,
    recommendList,
    pageNum,
    active,
    loading,
    loadMore,
    fetchIntelRecommand,
  }
}
