<template>
  <div px="4" pt="6 md:12" mb="md:20">
    <div text="lg md:2xl md:center #121212" m="b-2.5 md:b-10" font="500">
      {{ t('key.home.trust.partner') }}
      <!-- Trusted by 10+ partners -->
    </div>
    <div class="grid grid-cols-5 gap-x-2 gap-y-3 rounded-2" bg="#F6F6F6" md="bg-transparent px-4 gap-y-5" py="4" px="1">
      <div
        v-for="value in brandInfo" :key="value"
        w="full"
        flex="~ justify-center items-center"
        rounded="md"
        px="1"
        md="w-35 mx-auto"
      >
        <van-image :src="value" fit="cover" w="full" h="full" radius="0.5rem" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const store = useCommonStore()

const brandInfo = computed(() => store.commonData.brandInfo?.slice(0, 10) || [])
</script>

<style>
:root:root {
  --van-grid-item-content-active-color: none;
}

.az-grid .van-grid-item__content {
    padding: 5px;
}
</style>
