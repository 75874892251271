<template>
  <div mt="6" px="4" flex="~ md:items-center" space-x="md:4">
    <div v-for="item in OPTIONS" :key="item.value" flex="1 md:~ md:items-center" max-w="70" class="azgo-haptics-feedback" md="h-18 px-4 rounded-18 hover:bg-#0000000A cursor-pointer b-1 b-solid b-[#00000014]" @click="handleClick(item)">
      <div class="flex items-center justify-center" style="background: var(--tc-color-bg-index)" h="9 md:12" w="9 md:12" rounded="full" p="1.5 md:2.5" mr="md:3" mx="auto md:0" flex-shrink="0">
        <img :src="item.image" alt="" w="full" h="full" />
      </div>
      <div mt="2 md:0" class="whitespace-pre-wrap" font="400" text="xs md:base #121212 center">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AttrIcon from '~/assets/images/home/channel/attr.png'
import ExperienceIcon from '~/assets/images/home/channel/experience.png'
import TransportIcon from '~/assets/images/home/channel/transport.png'
import SimIcon from '~/assets/images/home/channel/sim.png'
import { ENUM_EventType, E_CATEGORY_TYPE } from '~/enums'
import type { TabClickEvent } from '~/types/event-tracking'

const { categories } = useSearchResult()

interface Option {
  value?: number
  label?: string
  image: string
}

const { currentCity } = useCurrentCity()

const OPTIONS = computed<Option[]>(() => {
  const optionMap = {
    464: AttrIcon,
    465: ExperienceIcon,
    466: TransportIcon,
    467: SimIcon,
  }

  return categories.value
    .filter(category => [E_CATEGORY_TYPE.ATTRACTIONS, E_CATEGORY_TYPE.EXPERIENCES].includes(category.labelId as E_CATEGORY_TYPE))
    .map(category => ({
      value: category.labelId,
      label: category.labelName,
      image: optionMap[category.labelId as keyof typeof optionMap] ?? optionMap[464],
    }))
})

function handleClick(item: Option) {
  trackEvent<TabClickEvent>({
    event: ENUM_EventType.TabClicks,
    click_cat_id: `${item.value}`,
    click_cat_name: item.label ?? '',
  })
  return navigateWithLocale({
    path: '/rank',
    query: {
      ...getCommonQuery(),
      destinationId: currentCity.value?.id,
      destinationName: currentCity.value?.cityName,
      countryCode: currentCity.value?.countryCode,
      tabId: item.value,
    },
  })
}
</script>

<style>

</style>
