<template>
  <div bg="md:#F6F6F6" px="4">
    <div mx="auto" max-w="300" mt="8 md:16" pt="md:8" text="center">
      <div class="font-500" text="lg md:2xl left md:center #121212">
        {{ t(pageTitle) }}
      </div>
      <div class="md:flex" mt="5 md:12" mb="md:16" pb="md:16">
        <div v-for="item in HowToEarnOptions" :key="item.icon" mb="6 md:0" class="flex flex-1 items-center md:mx-4 md:block md:text-center">
          <img h="8 md:10" w="8 md:10" ml="2 md:0" mb="md:6" class="flex-shrink-0" :src="item.image" />
          <div ml="3" text="left md:center">
            <div text="xs md:sm #121212" font="500">
              {{ item.description() }}
            </div>
            <div mt="md:2" text="2.5 md:sm #808080" font="300">
              {{ item.tips() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageHowToEarn1 from '@/assets/images/home/earn/1.png'
import ImageHowToEarn2 from '@/assets/images/home/earn/2.png'
import ImageHowToEarn3 from '@/assets/images/home/earn/3.png'

const { t } = useI18n()
const { commonData } = useCommonStore()

const introduce = computed(() => commonData.partnerInfo?.distStyle?.indexPageStyle?.introduce)
const pageTitle = computed(() => {
  return introduce.value?.title || t('key.business.howtoearn.howToEarnTitle')
})

const defaultIntroduce = computed(() => [
  {
    icon: '1',
    image: ImageHowToEarn1,
    description() {
      return t('key.business.howtoearn.1.title')
    },
    tips: () => {
      return t('key.business.howtoearn.1.desc')
    },
  },
  {
    icon: '2',
    image: ImageHowToEarn2,
    description() {
      return t('key.business.howtoearn.2.title')
    },
    tips: () => {
      return t('key.business.howtoearn.2.desc')
    },
  },
  {
    icon: '3',
    image: ImageHowToEarn3,
    description() {
      return t('key.business.howtoearn.3.title')
    },
    tips: () => {
      return t('key.business.howtoearn.3.desc')
    },
  },
])

const HowToEarnOptions = computed(() => {
  if (introduce.value?.items) {
    return introduce.value.items.map((item, index) => {
      return {
        icon: index + 1,
        image: item.img || defaultIntroduce.value[index].image,
        description: () => item.text || defaultIntroduce.value[index].description(),
        tips: () => defaultIntroduce.value[index].tips(),
      }
    })
  }

  return defaultIntroduce.value
})
</script>
